import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="team-container">
      <div className="container-team">
        <div className="section-title-team">
          <h2>Conheça a equipe principal</h2>
          <p>
            Conheça nossa equipe principal, composta por profissionais dedicados e talentosos, que são a espinha dorsal de nossa empresa. Cada membro traz uma rica experiência e especialização única, contribuindo para nossa missão de entregar soluções inovadoras e resultados excepcionais para nossos clientes. Juntos, eles cultivam a criatividade e lideram pelo exemplo, inspirando excelência em todos os níveis da organização.
          </p>
        </div>
        <div className="team-photos">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="team-member">
                <div className="thumbnail">
                  <img src={d.img} alt={d.name} className="team-img" />
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                  </div>
                </div>
              </div>
            ))
            : "Carregando..."}
        </div>
      </div>
    </div>
  );
};
